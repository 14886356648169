<template>
  <section id="triagerules">
    <b-card>
      <b-row>
        <b-col cols="12" md="12">
            
              <v-select
              placeholder="Select version"
                v-model="version"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="versions"
              />
              

</b-col>
            </b-row>
    </b-card>
    <!-- Triage rules Table Card -->
    <b-card no-body  v-if="this.version">
      <b-overlay
      :show="isLoading"
      rounded="sm"
    >

    <!-- Table Top -->
      <b-row v-if="this.version">
        <b-col cols="12" md="12">
          <div class="d-flex align-items-center justify-content-end mt-2 mr-2">
            
              <b-button variant="primary" @click="active = !active" v-if="canEditVersion">
                <feather-icon icon="PlusIcon" class="mr-25" />
                <span class="text-nowrap">Add rule</span>
              </b-button>
            
          </div>
        </b-col>
        
      </b-row>

      <triage-rules-table
        @reload="loadTriageRules"
        :table-data="data.triagerules"
        class="mt-2 mt-md-2"
        />
      </b-overlay>
    </b-card>
    <!--/ Triage rules Table Card -->

	<!-- SIDEBAR -->
	<b-sidebar
		width="40%"
		right
		shadow
		parent="body"
		default-index="1"
		spacer
		v-model="active"
		title="Triage rule settings"
		class="sidebar"
	>
		<div class="px-3 py-2">
			<validation-observer ref="simpleRules">
				<b-form @submit.prevent>
					<b-row v-if="this.version">
						<b-col md="12">
								<!-- default label -->
								<b-form-group
									label-cols-md="12"
									label="Triage rule name"
									label-for="input-default"
								>
									<validation-provider
										#default="{ errors }"
										name="Triage rule name"
										rules="required"
									>
										<b-form-input
											id="input-default"
					:state="errors.length > 0 ? false : null"
											v-model="newTriageRuleName"
											placeholder="New triage rule name"
										/>
					<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</b-form-group>
						</b-col>
					</b-row>

					<b-row>
						<!-- save -->
						<b-col md="12">
							<b-button
								
								type="submit"
								variant="primary"
								class="mr-1"
								@click.prevent="validationForm"
							>
								Save
							</b-button>
						</b-col>
					</b-row>
				</b-form>
			</validation-observer>
		</div>
	</b-sidebar>
	<!-- /SIDEBAR -->

  </section>
</template>

<script>
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BForm,
  BOverlay,
  BFormGroup,
  BFormInput,
  BButton,
  BSidebar,
} from "bootstrap-vue";
import vSelect from "vue-select";
import TriageRulesTable from "./TriageRulesTable.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import { required } from "@validations";

export default {
  //emits:['showAlert'],
  watch: {
    version(newVersion) {
      this.loadTriageRules()
    }
  },
  data() {
    return {
	  active: false,
      isLoading:false,
      newTriageRuleName:'',
      //selected: { title: 'Square' },
      optionUrgency: [{ title: "Urgent" }, { title: "Routine" }],
      optionAdvices: [{ title: "Contact GP" }, { title: "Call 111" }],
      optionExercises: [{ title: "Yes" }, { title: "No" }],
      selectedUrgency: [],
      selectedAdvices: [],
      selectedExercises: [],

        version:false,
      data: {
        triagerules: [],
        versions: [],
        
      },
    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BForm,
    BOverlay,
    BCardText,
    BFormGroup,
    BFormInput,
    vSelect,
    TriageRulesTable,
    BButton,
	BSidebar,
	ValidationProvider,
	ValidationObserver,
  },
  computed:{
    canEditVersion() {
      if (!this.version) return false;
      if (!this.isActiveVersion) return true;
      if (this.getLogin && this.getLogin.caneditactiveversion) return true;
      return false;
    },
    isActiveVersion() {
      if (!this.version) return false;
      var foundVersion = this.data.versions.filter(v=>{
        return v.id==this.version.value
      })
      if (foundVersion.length==1) {
        console.log(foundVersion)
        return foundVersion[0].active;
      }
      return false;
    },
    versions() {
      return this.data.versions.map(v=>{
        return {
          label:v.id+" - "+v.description+(v.active?' (ACTIVE)':''),
          value:v.id
        }
      })
    }
  },
  methods:{
    getLabel(option) {
      console.log('getlabel', option)
      return option.displayValue;
    },
	async validationForm() {
		this.$refs.simpleRules.validate().then((success) => {
			if (success) {
				this.createNewTriageRule();
			}
		});
	},
    async createNewTriageRule() {
        if (!this.newTriageRuleName || !this.newTriageRuleName.trim().length) {
          alert("Please enter a triage-rule name");
          return;
        }
        this.isLoading = true;
        var url = '/triagerules'
        const res = await this.callApi('post', url, {tv_id:this.version.value, name:this.newTriageRuleName})
        this.newTriageRuleName = '';
        this.loadTriageRules();
		this.active = false;
    },
    async loadTriageRules() {
      console.log('loadTriageRules', this.version)
      if (!this.version) return;
      this.isLoading = true;
      var url = '/triagerules/version/'+this.version.value;
      const res = await this.callApi('get', url);
      this.data.triagerules = res.data.result;
      this.isLoading = false;
    },
    async loadVersions(autoSelectVersionId) {
      var url = '/triageruleversions';
      const res = await this.callApi('get', url);
      this.data.versions = res.data.result;
      if (autoSelectVersionId) {
        var foundVersions = this.data.versions.filter(v=>v.id==autoSelectVersionId)
        console.log('foundVersions', foundVersions)
        if (foundVersions.length) {
          this.version = {label:foundVersions[0].id+" - "+foundVersions[0].description, value:foundVersions[0].id};
          console.log('version', this.version)
          this.loadTriageRules()
        }
      }
    },
  },
  mounted() {
    var autoSelectVersionId = false;
    if (this.$route.params.id) {
      autoSelectVersionId = this.$route.params.id
    }
    this.loadVersions(autoSelectVersionId);
    this.loadTriageRules();
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.popover-body {
  color: #6e6b7b !important;
}
</style>